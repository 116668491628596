/** @jsxImportSource @emotion/react */

import { InputText as InputTextPR } from "primereact/inputtext";

import { FormErrorMessage } from "validation/form-error-message";

import css from "../form-element.module.css";

const InputText = ({ required, formik, size, ...data }) => {
  // const elWidth = `${size || "100"}%`;

  const getLabel = data?.label && (
    <label>
      {data.label}
      {required && <span>*</span>}
    </label>
  );

  const elProps = {
    ...Object.keys(data).reduce((acc, key) => {
      if (key !== "data") {
        acc[key] = data[key];
      }
      return acc;
    }, {}),
  };

  const getElement = <InputTextPR {...elProps} />;

  return (
    <div className={css.formEl}>
      {getLabel}
      {getElement}
      {required && <FormErrorMessage name={data?.name} formik={formik} />}
    </div>
  );
};

export default InputText;
