/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { useMemo, useState, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

import "./css.css";
import css from "./custom-div-table.module.css";

function generateFilterObject(keys, searchText) {
  const filterObject = {};
  for (const key of keys) {
    filterObject[key] = {
      value: searchText?.toLowerCase() || "",
    };
  }
  return filterObject;
}

export default function CustomDivTable(props) {
  const {
    sortable = false,
    title,
    columns,
    rows,
    count,
    selection,
    onSelect,
    selectionMode,
    filterKeys,
    reorderableColumns = false,
    reorderableRows = false,
    onRowReorder,
    sortField,
    sortOrder,
    onSelectionChange = (e) => {
      try {
        onSelect(e.value.data);
      } catch (error) {}
    },
    noTableSort,
    showSearch = true,
    searchOutside = false,
    activeBtnGroup,
    groupBy,
    hasFooter,
    rowGroupHeaderTemplate,
    multiSortMeta = [],
    onPage,
    rowCount,
    toolbar,
    isFullWidth = true,
    dtRef,
    emptyMessage = "No classes found.",
    onRowClick,
    expandableRowGroups,
  } = props;
  const [searchText, setSearchText] = useState("");

  const [tableSortField, setTableSortField] = useState(sortField);
  const [tableSortOrder, setTableSortOrder] = useState(sortOrder);
  const [tableRows, setTableRows] = useState(rowCount);
  const [tablePage, setTablePage] = useState(0);
  const [expandedRows, setExpandedRows] = useState([]);

  const searchTimeout = useRef(null);

  const theme = useTheme();

  const filters = useMemo(
    () => filterKeys && generateFilterObject(filterKeys, searchText),
    [filterKeys, searchText],
  );

  const rowObjs = useMemo(() => {
    return rows
      ?.map((data, i) => {
        const row = { data };
        for (const field of columns) {
          row[field.name] = field.valFn
            ? field.valFn(data, i)
            : data[field.name];
        }
        return row;
      })
      .filter((item) => {
        if (onPage || !filters) return true;
        for (const key of Object.keys(filters)) {
          if (
            item.data[key]?.toLowerCase().includes(searchText?.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
  }, [rows, columns, filters, searchText, onPage]);

  const tableRowsPerPage = useMemo(() => {
    const options = [5, 10, 25, 50];
    const filteredOptions = options.filter(
      (option) => option < rowObjs?.length,
    );
    filteredOptions.push(rowObjs?.length);

    return filteredOptions;
  }, [rowObjs?.length]);

  const handleSearch = (event) => {
    clearTimeout(searchTimeout.current);

    searchTimeout.current = setTimeout(() => {
      if (onPage) {
        setTablePage(0);

        let order = "desc";

        if (tableSortOrder) {
          order = tableSortOrder === 1 ? "asc" : "desc";
        }

        onPage({
          offset: 0,
          limit: tableRows,
          sort: tableSortField,
          order: order,
          search: event.target.value,
        });
      }
    }, 300);

    setSearchText(event.target.value);
  };

  const footer = `${rows ? rows.length : 0} Results`;
  const columnEls = (columns || []).map((col) => {
    if (activeBtnGroup === "upcoming" && col.name === "numAttended") {
      return false;
    }
    if (activeBtnGroup === "active" && col.name === "archive") {
      return false;
    }

    const headerEl = col?.helper ? (
      <>
        {col?.display} <span css={css().helper}>{col.helper}</span>
      </>
    ) : (
      col?.display
    );

    const getColWidth = (name) => {
      const types = {
        archive: 100,
        remove: 100,
        cancel: 100,
        splits: 75,
        edit: 70,
        go: 40,
        default: null,
      };

      return types[name || "default"];
    };

    return (
      <Column
        key={col.name}
        field={col.name}
        header={headerEl}
        sortable={col.sortable}
        hidden={col.hidden}
        align={col?.align || "left"}
        alignHeader={col?.alignHeader || "left"}
      />
    );
  });

  const pageFn = onPage
    ? (e) => {
        let order = undefined;
        if (e.sortField && e.sortOrder) {
          order = e.sortOrder === 1 ? "asc" : "desc";
        }
        onPage({
          offset: e.first,
          limit: e.rows,
          sort: e.sortField,
          order,
          search: searchText,
        });
        setTableSortField(e.sortField);
        setTableSortOrder(e.sortOrder);
        setTableRows(e.rows);
        setTablePage(e.page);
      }
    : (e) => {};

  const dataTableOptions = {
    value: rowObjs,
    onPage: pageFn,
    lazy: !!onPage,
    first: tableRows && tablePage ? tableRows * tablePage : null,
    onSort: (e) => pageFn({ ...e, page: 0, first: 0, tablePage: 0 }),
    paginator: count >= tableRows,
    rows: tableRows,
    totalRecords: count,
    rowsPerPageOptions: tableRowsPerPage,
    footer: hasFooter ? footer : null,
    selectionMode: selectionMode,
    selection: selection,
    onSelectionChange,
    reorderableColumns: reorderableColumns,
    globalFilterFields: ["long_name", "class_number"],
    emptyMessage: emptyMessage,
    reorderableRows: reorderableRows,
    onRowReorder: (...e) => onRowReorder(...e),
    sortField: tableSortField,
    sortMode: multiSortMeta?.length ? "multiple" : "single",
    multiSortMeta: multiSortMeta,
    sortOrder: tableSortOrder,
    rowGroupMode: rowGroupHeaderTemplate ? "subheader" : null,
    rowGroupHeaderTemplate: rowGroupHeaderTemplate,
    groupRowsBy: groupBy,
    onRowClick,

    expandedRows: expandedRows,
    onRowToggle: (e) => setExpandedRows(e.data),
    expandableRowGroups,
    // style: { width: "100%" },
  };

  const tableSearchOutside = searchOutside &&
    showSearch &&
    filterKeys?.length > 0 && (
      <div className={css.search} data-type="searchoutside">
        <div>
          <InputText
            value={searchText}
            onChange={handleSearch}
            placeholder="Search..."
          />
        </div>
      </div>
    );

  const tableSearchInside = !searchOutside &&
    showSearch &&
    filterKeys?.length > 0 && (
      <div className={css.search}>
        <InputText
          value={searchText}
          onChange={handleSearch}
          placeholder="Search..."
        />
      </div>
    );

  return (
    <>
      {tableSearchOutside}

      <div className={css.divTable}>
        {title && <h3>{title}</h3>}
        {tableSearchInside}
        {toolbar}

        <DataTable
          {...dataTableOptions}
          dataKey="id"
          ref={dtRef}
          className={css.datatable}
        >
          {selectionMode && selectionMode === "checkbox" && (
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
              exportable={false}
            ></Column>
          )}
          {reorderableColumns && reorderableRows && (
            <Column rowReorder exportable={false} />
          )}
          {columnEls}
        </DataTable>
      </div>
    </>
  );
}
