import React from "react";

import MenuItem from "./menu-item";

import css from "./menu.module.css";

function Menu(props) {
  const { activePage, hideMenu, pages = [], setNavOpen } = props;

  const menuItem = (page) => (
    <MenuItem page={page} activePage={activePage} setNavOpen={setNavOpen} />
  );

  return (
    <ul className={css.mainMenu}>
      {!hideMenu &&
        pages.map((page, index) => (
          <React.Fragment key={index}>{menuItem(page)}</React.Fragment>
        ))}
    </ul>
  );
}

export default Menu;
