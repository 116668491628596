import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { useOutletContext } from "react-router-dom";

import { useQuery } from "hooks";

import DataScroller from "components/data-scroller/data-scroller";
import FilterBtnGroup from "components/filter-btn-group/filter-btn-group";
import EmptyBlock from "components/empty-block/empty-block";
import TitleBlock from "components/title-block/title-block";
import Button from "components/button/button";
import Icon from "components/icon/icon";

import ShowModal from "./show-modal";
import ShowCancelModal from "./show-cancel-modal";
import ShowsShowCard from "./shows-show-card";

import css from "./shows.module.css";

export default function Shows(props) {
  const activeShow = useRef(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [activeBtnGroup, setActiveBtnGroup] = useState("all");

  let queryUrl = "/shows";
  if (activeBtnGroup !== "all") {
    queryUrl += `?upcoming=${activeBtnGroup === "upcoming"}`;
  }

  const { data, refetch, isLoading } = useQuery(queryUrl);

  function editShow(it) {
    activeShow.current = it;
    setShowEditModal(true);
  }

  const filterBtnGroup = (
    <FilterBtnGroup
      options={["Upcoming", "Completed"]}
      activeBtnGroup={activeBtnGroup}
      setActiveBtnGroup={setActiveBtnGroup}
    />
  );

  const handleCreate = () => {
    activeShow.current = null;
    setShowEditModal(true);
  };

  const btnCreate = data && (
    <Button
      icon={<Icon icon="Plus" />}
      iconPos="left"
      label="Create New Show"
      onClick={handleCreate}
    />
  );

  const itemTemplate = (it) => (
    <ShowsShowCard data={it} onClick={() => editShow(it)} />
  );

  const filteredData = data?.data.filter((row) => {
    if (activeBtnGroup === "all") {
      return true;
    }

    const today = moment().startOf("day").toISOString();

    const isCurrent = row.end_at <= today;

    return activeBtnGroup === "upcoming" ? !isCurrent : isCurrent;
  });

  const numOfRows = data?.data?.length;

  const actions = [btnCreate];

  const showsBlock = (
    <>
      <TitleBlock>
        {filterBtnGroup}
        {actions.map((btn, index) => (
          <React.Fragment key={index}>{btn}</React.Fragment>
        ))}
      </TitleBlock>

      <DataScroller
        value={filteredData}
        itemTemplate={itemTemplate}
        rows={numOfRows}
      />
    </>
  );

  const spinningBlock = <EmptyBlock title="Downloading shows!" />;

  const emptyBlock = (
    <EmptyBlock
      title="No Shows Yet!"
      message="Start by adding a Show."
      btnLabel="Add a Show"
      onClick={handleCreate}
    />
  );

  const content = isLoading
    ? spinningBlock
    : data?.data
    ? showsBlock
    : emptyBlock;

  const modals = (
    <>
      {showEditModal && (
        <ShowModal
          setShowModal={setShowEditModal}
          show={activeShow.current}
          onSuccess={refetch}
        />
      )}

      {showCancelModal && (
        <ShowCancelModal
          setShowModal={setShowCancelModal}
          show={activeShow.current}
          onAccept={refetch}
        />
      )}
    </>
  );

  return (
    <div className={css.shows}>
      {content}
      {modals}
    </div>
  );
}
