/** @jsxImportSource @emotion/react */

import { useContext, useState, useEffect } from "react";
import { Formik, Form } from "formik";

import Client from "client";
import { useMutation } from "hooks";
import AuthContext from "../../context/auth-context";

import Icon from "components/icon/icon";
import { FormElement } from "components/form-element/form-element";
import Button from "components/button/button";
import Logo from "components/logo/logo";

import css from "./login.module.css";
import { cssjs } from "./css";

const initialValues = {
  email: "",
  password: "",
};

const validate = () => ({});

const Login = () => {
  const { login } = useContext(AuthContext);
  const [hasError, setHasError] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [chapters, setChapters] = useState([]);

  const { mutateAsync: onSubmit, isLoading } = useMutation({
    mutationKey: "login_user",
    mutationFn: async (data) => Client.post("/auth/login", data),
    onSuccess: (data) => {
      login(data);
    },
    onError: () => {
      setHasError(true);
    },
  });

  const handlePasswordReset = async () => {
    // Perform password reset function here
    console.log("handle reset functionality");
    try {
      let username = document.getElementById("username").value;

      await Client.post("/auth/forgot-password", { username });
      alert("Check your email for a link to reset your password!");
    } catch (err) {
      console.log(err);
      alert(
        "Error occured trying to send password reset email, the email was not sent",
      );
    }
  };

  useEffect(() => {
    const getChapters = async () => {
      const chapts = await Client.get("/chapters");
      // console.log(chapts);
      setChapters(chapts.data);
    };
    getChapters();
  }, []);

  function handleErrorBtn() {
    setTimeout(() => setHasError(false), 500);
  }

  const errorBtn = (
    <Button label="Try Again" color="danger" onClick={handleErrorBtn} />
  );

  const errorBox = (
    <div className={css.errorBox}>
      <Icon icon="ErrorX" stroke={null} />

      <div className={css.msg}>
        <h2>Error!</h2>
        <p>Wrong Username or Password</p>
      </div>

      {errorBtn}
    </div>
  );

  const ressetPassword = isResettingPassword && (
    <div className={css.formBlock}>
      <div className={css.intro}>
        <h1>Reset your password</h1>
        <p>If you have forgotten your password you can reset it here.</p>
      </div>

      <div className={css.elements}>
        <FormElement
          element="input"
          elementType="text"
          id="username"
          name="username"
          label="Username"
        />
      </div>

      <Button label="Send My Password" onClick={handlePasswordReset} />
    </div>
  );

  const header = <Logo />;

  const form = (formik) => (
    <div className={css.formBlock} css={cssjs(isResettingPassword)}>
      <div className={css.intro}>
        <h1>Log in to your account</h1>
        <p>Welcome back! Please enter your details.</p>
      </div>

      <div className={css.elements}>
        <FormElement
          element="InputText"
          id="username"
          name="username"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.username}
          label="User Name"
        />

        <FormElement
          element="Password"
          id="password"
          name="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.password}
          label="Password"
        />
      </div>

      <Button
        label="Forgot Password"
        onClick={() => setIsResettingPassword(true)}
        variant="link"
      />

      <Button
        label="Sign in"
        onClick={formik.handleSubmit}
        loading={isLoading}
        disabled={isLoading || Object.values(formik.errors).length || hasError}
        type="submit"
      />
    </div>
  );

  const account = isResettingPassword ? (
    <Button
      icon={<Icon icon="ChevronLeft" />}
      iconPos="left"
      label="Back to Log in"
      onClick={() => setIsResettingPassword(false)}
      variant="link"
    />
  ) : null;

  return (
    <div className={css.container}>
      <div className={css.block}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
        >
          {(formik) => (
            <Form>
              {hasError && errorBox}
              {header}
              {ressetPassword}
              {form(formik)}
              {account}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
