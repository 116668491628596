import React from "react";
import { useState } from "react";

import CurrentUserBlock from "components/current-user-block/current-user-block";

import Menu from "../menu/menu";
import NestedMenu from "../menu/menu-nested";
import Logo from "../logo/logo";
import MobTrigger from "./header-trigger";

import css from "./header.module.css";

export default function Header({
  hideMenu = false,
  menuTabs,
  nestedMenuTabs,
  nestedMenuTitle,
}) {
  const [navOpen, setNavOpen] = useState(false);

  const submenu = nestedMenuTabs ? (
    <NestedMenu
      currentShow={nestedMenuTabs}
      pages={nestedMenuTabs}
      title={nestedMenuTitle}
    />
  ) : null;

  const content = (
    <div className={css.content}>
      <Logo />
      {/* <Search /> */}

      <nav className={`${css.nav} ${navOpen ? css.opened : ""}`}>
        <Menu
          navOpen={navOpen}
          setNavOpen={setNavOpen}
          hideMenu={hideMenu}
          pages={menuTabs}
        />
        {submenu}
      </nav>
    </div>
  );

  const user = <CurrentUserBlock navOpen={navOpen} />;

  return (
    <header className={css.header}>
      <MobTrigger navOpen={navOpen} setNavOpen={setNavOpen} />
      {content}
      {user}
    </header>
  );
}
