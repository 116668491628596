import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";

import css from "../button/button.module.css";

const CustomPdfButton = ({
  document,
  label = "Export to PDF",
  loadingLabel = "Loading document...",
  fileName = "file",
  color = "primary",
  variant = "outlined",
}) => {
  const cssButton = `${css.button} ${css[color]} ${css[variant]}`;

  const options = {
    document,
    fileName: `${fileName}.pdf`,
    className: cssButton,
  };

  return (
    <PDFDownloadLink {...options}>
      {({ loading }) => (loading ? loadingLabel : label)}
    </PDFDownloadLink>
  );
};

export default CustomPdfButton;
