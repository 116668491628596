import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import Client from "client";

import HhhhLogo from "components/icons/icon4h-logo";

// import css from "./confirm-custodian.module.scss";
import css from "./confim-custodian.module.css";
import Header from "components/header/header";

function ConfirmCustodian(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [redir, setRedir] = useState(false);

  const navigate = useNavigate();

  let invitation = searchParams.get("invitation");
  let token = searchParams.get("token");

  const processAcceptance = async (invitation, token) => {
    const data = { invitation, token };
    const res = await Client.post("/auth/accept-custodian", data);
    if (res.invite?.length > 0) {
      const invite = res.invite[0];

      const name =
        invite.member.user.first_name + " " + invite.member.user.last_name;
      const dob = invite.member.user.birth_at;
      const club = invite.member.user.clubs[0].club.name;

      //Now redirect somewhere
      navigate(`/custodian-confirmed?name=${name}&dob=${dob}&club=${club}`);
    }
  };

  useEffect(() => {
    processAcceptance(invitation, token);
  }, []);

  const logo = (
    <div className={css.logo}>
      <HhhhLogo />
    </div>
  );

  const copy = (
    <div className={css.copy}>
      <h1>You confirmed this is your rider.</h1>
      <p>
        Next, accept show waivers and pay for classes on the{" "}
        <a href="#">iOS app</a> or on the <a href="#">Android app.</a>
      </p>
    </div>
  );
  const info = (
    <div className={css.info}>
      {arrData.map((item, index) => (
        <div key={index} className={css.row}>
          <div className={css.label}>{item?.label}</div>
          <div className={css.val}>{item?.value}</div>
        </div>
      ))}
    </div>
  );

  const navBlock = (
    <div className={css.navBlock}>
      <Header hideMenu={true} />
    </div>
  );

  return (
    <div className={css.Login}>
      {navBlock}

      <div className={css.container}>
        {copy}
        {info}
      </div>
    </div>
  );
}

const arrData = [
  {
    label: "Name",
    value: "Jamie Smith-Jingleheiner",
  },
  {
    label: "DOB",
    value: "07/29/2014",
  },
  {
    label: "Club",
    value: "Midnight Riders",
  },
];

export default ConfirmCustodian;
