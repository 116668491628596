import React from "react";
import { InputTextarea as InputTextareaPR } from "primereact/inputtextarea";

import css from "../form-element.module.css";

const InputTextarea = (data) => {
  const getLabel = data?.label && <label>{data.label}</label>;

  const elProps = {
    ...Object.keys(data).reduce((acc, key) => {
      if (key !== "data") {
        acc[key] = data[key];
      }
      return acc;
    }, {}),
  };

  const getElement = <InputTextareaPR {...elProps} />;

  return (
    <div className={css.formEl}>
      {getLabel}
      {getElement}
    </div>
  );
};

export default InputTextarea;
