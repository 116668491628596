import React from "react";

import { DataScroller as PrDataScroller } from "primereact/datascroller";

import css from "./data-scroller.module.css";

const DataScroller = ({ value, itemTemplate, rows }) => {
  const options = {
    value,
    itemTemplate,
    rows,
    className: css.dataScroller,
  };

  return <PrDataScroller {...options} />;
};

export default DataScroller;
