/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import Icon from "components/icon/icon";

import Submenu from "./menu-submenu";

import css from "./menu.module.css";

const MenuItem = ({ setNavOpen, page }) => {
  const loc = useLocation();

  const activePage = new RegExp(`^${page.linkTo}`).test(loc.pathname);

  const [isOpen, setIsOpen] = useState(activePage);

  const theme = useTheme();

  const toggle = (isOpen) => (
    <Icon
      icon="ChevronDown"
      isOpen={isOpen}
      stroke={theme?.text?.placeholder}
    />
  );

  // const LinkItem = ({ children, to }) => (
  //   <NavLink
  //     to={to}
  //     className={useLocation().pathname === to ? css.active : null}
  //   >
  //     {children}
  //   </NavLink>
  // );

  const handleLinkItem = () => {
    setNavOpen(false);
  };

  return (
    <li>
      {!page.children ? (
        <NavLink to={page.linkTo} onClick={handleLinkItem}>
          <div className={css.link}>
            {page?.icon && (
              <Icon
                icon={page.icon}
                fill={page?.fill || theme?.text?.placeholder}
                stroke={page?.stroke}
              />
            )}
            <span className={css.pageTitle}>{page.title}</span>
          </div>
        </NavLink>
      ) : (
        <div
          className={`${css.link} ${activePage ? css.active : null}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {page?.icon && (
            <Icon icon={page.icon} fill={page?.fill} stroke={page?.stroke} />
          )}
          <span className={css.pageTitle}>{page.title}</span>
          {isOpen ? toggle(true) : toggle(false)}
        </div>
      )}

      {page.children && isOpen && (
        <Submenu page={page} setNavOpen={setNavOpen} />
      )}
    </li>
  );
};

export default MenuItem;
