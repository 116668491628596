import React from "react";
import { ButtonGroup } from "primereact/buttongroup";

import Button from "../button/button";

import css from "./filter-btn-group.module.css";

export default function FilterBtnGroup(props) {
  const {
    options = [],
    objectOptions,
    activeBtnGroup = "all",
    setActiveBtnGroup = () => {},
    style,
  } = props;

  let btnEls = null;

  if (objectOptions) {
    btnEls = objectOptions.map((option) => {
      const opt = JSON.parse(option);
      const buttonKey = opt.id;

      return (
        <Button
          key={buttonKey}
          label={opt?.value}
          variant={buttonKey === activeBtnGroup ? css.active : null}
          onClick={() => setActiveBtnGroup(buttonKey)}
        />
      );
    });
  } else {
    btnEls = options.map((opt) => {
      const buttonKey = opt.replace(" ", "-").toLowerCase();

      return (
        <button
          key={buttonKey}
          className={buttonKey === activeBtnGroup ? css.active : null}
          onClick={() => setActiveBtnGroup(buttonKey)}
          variant="btnGroup"
        >
          {opt}
        </button>
      );
    });
  }

  return (
    <ButtonGroup className={css.filterBtnGroup}>
      <button
        onClick={() => setActiveBtnGroup("all")}
        className={activeBtnGroup === "all" ? css.active : null}
        variant="btnGroup"
      >
        All
      </button>
      {btnEls}
    </ButtonGroup>
  );
}
