import React, { useContext } from "react";
import AuthContext from "context/auth-context";

import Icon from "components/icon/icon";

import css from "./current-user-block.module.css";

const CurrentUserBlock = ({ navOpen }) => {
  const { logout, userData } = useContext(AuthContext);

  const avatar = (
    <div className={css.avatar}>
      <Icon icon="Users" />
    </div>
  );

  const info = (
    <div className={css.info}>
      <p>
        {userData?.first_name} {userData?.last_name}
      </p>
      <p>{userData?.email || ""}</p>
    </div>
  );

  const btnLogout = (
    <div className={css.logout} onClick={logout}>
      <Icon icon="Logout" />
    </div>
  );

  return (
    <div className={`${css.userBlock} ${navOpen ? css.opened : ""}`}>
      {avatar}
      {info}
      {btnLogout}
    </div>
  );
};

export default CurrentUserBlock;
