import React from "react";
import { Button as PrButton } from "primereact/button";

import css from "./button.module.css";

const Button = ({
  type = "button",
  color = "primary",
  icon = null,
  iconPos = "left",
  label = null,
  onClick,
  variant = "contained",
  disabled = false,
}) => {
  const cssButton = [css.button, css[color], css[variant]];

  const options = {
    type,
    icon,
    iconPos,
    label,
    onClick,
    disabled,
    className: cssButton,
    unstyed: "unstyled",
  };

  return <PrButton {...options} />;
};

export default Button;
