import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import css from "./menu.module.css";

const Submenu = ({ setNavOpen, page }) => {
  const loc = useLocation();

  const linkEls = page?.children?.map((child) => {
    const activePage = loc.pathname === child.linkTo;

    return (
      <li key={child.linkTo} className={activePage ? css.isActive : null}>
        <NavLink to={child.linkTo} onClick={() => setNavOpen(false)}>
          {child.title}
        </NavLink>
      </li>
    );
  });

  return <ul className={css.submenu}>{linkEls}</ul>;
};

export default Submenu;
