import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import Icon from "components/icon/icon";

import css from "./menu.module.css";

const MenuNested = (props) => {
  const { pages = [], title } = props;

  const loc = useLocation();

  const pageEls = pages.map((page, index) => {
    const activePage =
      loc.pathname === `${page.linkTo}` ||
      page?.highlight?.some((l) => l === loc.pathname);

    return (
      <li key={index} className={activePage ? css.isActive : null}>
        <NavLink to={page.linkTo}>{page.title}</NavLink>
      </li>
    );
  });

  return (
    <div className={`${css.submenu} ${css.nestedMenu}`}>
      <div className={css.title}>
        <Icon icon="HorseHead" fill="#667085" stroke={null} />
        <span>{title}</span>
      </div>
      <ul>{pageEls}</ul>
    </div>
  );
};

export default MenuNested;
