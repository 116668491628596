import React from "react";

import { square } from "emotion/utils";

import css from "./rider-list.module.css";

export default function RiderList(props) {
  const { data, isLoading, noClass } = props;

  if (noClass) {
    return (
      <div className={css.noRiders}>
        <p>No classes found for show</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={css.noRiders}>
        <p>Loading riders...</p>
      </div>
    );
  }

  if (!data?.length) {
    return (
      <div className={css.noRiders}>
        <p>Select a class from the list to the left.</p>
      </div>
    );
  }

  const riderEls = data
    .sort((a, b) =>
      a.registrant.rider_number < b.registrant.rider_number ? -1 : 1,
    )
    .map((r) => {
      const awardEl = r.awards.map((aw) => (
        <img
          key={aw.id}
          source={aw.img_link}
          url={aw.img_link}
          style={{ ...square(32) }}
          alt=""
        />
      ));

      return (
        <div key={r.user_id} className={css.rider}>
          <div className={css.col}>{r.registrant.rider_number}</div>
          <div className={css.col}>
            {r.first_name} {r.last_name}
          </div>
          <div className={css.col}>{r.animal.name}</div>
          <div className={css.col}>{awardEl}</div>
        </div>
      );
    });

  return <div className={css.riderList}>{riderEls}</div>;
}
