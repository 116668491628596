import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import AuthLayout from "./layouts/auth-layout";
import AdminRouter from "./routes/admin";
import LoginRouter from "./routes/login";
import { ErrorBoundary } from "./components/error-boundary";
import { NotFound } from "./pages/not-found/not-found";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./scss/main.scss";
import Marketing from "pages/marketing/marketing";
import PrivacyPolicy from "pages/privacy-policy/privacy-policy";
import { ThemeProvider } from "@emotion/react";
import { appThemes } from "emotion/utils";
import { useState } from "react";

function App() {
  const [thm] = useState("light");

  return (
    <ThemeProvider theme={appThemes(thm)}>
      <ErrorBoundary>
        <BrowserRouter>
          <QueryClientProvider client={new QueryClient()}>
            <Routes>
              <Route path="/" element={<Marketing />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />

              <Route element={<AuthLayout />}>
                {LoginRouter}
                {AdminRouter}
              </Route>

              <Route path="*" element={<NotFound />} />
            </Routes>
          </QueryClientProvider>
        </BrowserRouter>{" "}
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
