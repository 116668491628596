import React, { Component } from "react";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import css from "./error-boundary.module.css";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.toast = React.createRef();
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error, info);

    this.toast.current.show({
      severity: "error",
      summary: "Error",
      detail: "An unexpected error occurred.",
      life: 5000,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={css.container}>
          <h1>
            Oops.
            <br />
            Something went wrong.
          </h1>
          <a href="/">Back</a>
        </div>
      );
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
