/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Formik, Form } from "formik";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Client from "client";
import { required } from "validation/form";
import { FormErrorMessage } from "validation/form-error-message";

import { fullWidth } from "emotion/utils";

import Stats from "components/stats/stats";
import EventMapInput from "../components/event-map-input/event-map-input";
import Button from "components/button/button";
import Icon from "components/icon/icon";
import TitleBlock from "components/title-block/title-block";
import Divider from "components/divider/divider";
import { FormElement } from "components/form-element/form-element";

import LocationInput from "../location-input";
import ShowOverviewDateInput from "../show-overview-date-input";
import ShowClassModal from "../show-class-modal/show-class-modal";
import ShowModal from "../../shows/show-modal";
import ShowClassRidersAddRegistrantModal from "../show-class-riders/show-class-riders-add-registrant.modal";
// import { css } from "../css";
import ShowRegistrantsAddModal from "../show-registrants-add-modal";

import css from "./show-overview.module.css";

const validate = (values) => {
  const requiredFields = [
    "registrationOpenAt",
    "postEntryAt",
    "startAt",
    "endAt",
    "description",
    "locationId",
  ];

  const errors = {};
  requiredFields.forEach((field) => {
    required(errors, values, field);
  });

  return errors;
};

export default function ShowOverview(props) {
  const { show, setActiveBtnGroup, onSuccess } = useOutletContext();

  const [showAddClassesModal, setShowAddClassesModal] = useState(false);
  //const [showAddRiderModal, setShowAddRiderModal] = useState(false);
  const [showManageRiderClasses, setShowManageRiderClasses] = useState(false);

  const [showEditShowModal, setShowEditShowModal] = useState(false);
  const [activeRider, setActiveRider] = useState(null);

  const theme = useTheme();

  const navigate = useNavigate();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const stats = [
    { title: "Riders", icon: "Users2", entryFunc: () => show.total_registered },
    { title: "Clubs", icon: "Star3", entryFunc: () => show.total_clubs },
    {
      title: "Revenue",
      icon: "Currency",
      entryFunc: () => formatter.format(show.total_earned),
    },
  ];

  const {
    mutateAsync: submitRequest,
    isLoading,
    isError,
  } = useMutation({
    mutationKey: "create-edit-show",
    mutationFn: async (data) => Client.put(`/shows/${show.id}`, data),
    networkMode: "always",
    onSuccess: () => {
      console.log("calling on success to refetch and reload!");
      onSuccess && onSuccess();
    },
  });

  const onSubmit = async (values) => {
    submitRequest(values);
  };

  if (isLoading || !show) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error!!!</div>;
  }

  const initialValues = {
    registrationOpenAt: show.registration_open_at || "",
    postEntryAt: show.post_entry_at || show.start_at,
    startAt: show.start_at,
    endAt: show.end_at,
    description: show.description || "",
    avatar: show.avatar || null,
    waiver: show.waiver || null,
    locationId: show.location?.id || "",
    location: show.location || "",
    preEntryCost: show.pre_entry_cost || "",
    postEntryCost: show.post_entry_cost || "",
  };

  function getIsShowPast(eventEnd) {
    return eventEnd <= new Date().toISOString() ? true : false;
  }

  const titleBlock = (
    <TitleBlock>
      <h1>{show.name}</h1>
    </TitleBlock>
  );

  const btnAddClasses = (
    <Button
      icon={<Icon icon="Plus" />}
      iconPos="left"
      label="Add classes"
      onClick={() => {
        // alert("Add classes functionality");
        setShowAddClassesModal(true);
      }}
      color="primary"
      variant="outlined"
    />
  );

  const btnEditShow = (
    <Button
      icon={<Icon icon="Plus" />}
      iconPos="left"
      label="Edit Show"
      onClick={() => {
        setShowEditShowModal(true);
      }}
      color="primary"
      variant="outlined"
    />
  );

  const btnAddRider = (
    <Button
      icon={<Icon icon="Plus" />}
      iconPos="left"
      label="Add rider"
      onClick={() => {
        // alert("Add rider functionality");
        //setShowAddRiderModal(true);
        navigate(`/admin/shows/${show.id}/registrants?add=true`);
        //Go to Riders
      }}
      color="primary"
      variant="outlined"
    />
  );

  // const btnEditWaiver = (
  //   <Button
  //     icon={<Icon icon="Edit" />}
  //     iconPos="left"
  //     label="Edit waiver"
  //     onClick={() => alert("Add rider functionality")}
  //     color="primary"
  //     variant="outlined"
  //   />
  // );

  const btnPrintHighPointReports = (
    <Button
      icon={<Icon icon="Printer" />}
      iconPos="left"
      label="Print high point reports"
      onClick={() => {
        alert("Print high point reports");
      }}
      color="primary"
      disabled={show?.show_type === "FAIR"}
      variant="outlined"
    />
  );

  const actions = (
    <div className={css.actions}>
      {[
        btnEditShow,
        btnAddClasses,
        btnAddRider,
        // btnEditWaiver,
        btnPrintHighPointReports,
      ].map((btn, index) => React.cloneElement(btn, { key: index }))}
    </div>
  );

  const hero = (formik) => (
    <div className={css.hero}>
      <EventMapInput
        label="Event Image"
        name="avatar"
        formik={formik}
        readOnly={true}
      />

      <Stats data={stats} isDetail={true} isOver={getIsShowPast(show.end_at)} />
    </div>
  );

  const form = (formik) => (
    <Form className={css.form}>
      <div className={css.row}>
        <FormElement
          element="Calendar"
          id="registrationOpenAt"
          name="registrationOpenAt"
          label="Registration open"
          formik={formik}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.registrationOpenAt}
          readOnly={true}
          disabled={true}
          max={formik.values.postEntryAt}
          required
        />

        <FormElement
          element="Calendar"
          id="postEntryAt"
          name="postEntryAt"
          label="Post entry date"
          formik={formik}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.postEntryAt}
          readOnly={true}
          disabled={true}
          min={formik.values.registrationOpenAt}
          max={formik.values.startAt}
          required
        />

        {/* <ShowOverviewDateInput
          label="Show start date"
          name="startAt"
          readOnly={true}
          formik={formik}
          min={formik.values.postEntryAt}
          max={formik.values.endAt}
        /> */}
        <FormElement
          element="Calendar"
          id="startAt"
          name="startAt"
          label="Show start date"
          formik={formik}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.registrationOpenAt}
          readOnly={true}
          disabled={true}
          min={formik.values.postEntryAt}
          max={formik.values.endAt}
          required
        />

        {/* <ShowOverviewDateInput
          label="Show end date"
          name="endAt"
          readOnly={true}
          formik={formik}
          min={formik.values.startAt}
        /> */}
        <FormElement
          element="Calendar"
          id="endAt"
          name="endAt"
          label="Show end date"
          formik={formik}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.endAt}
          readOnly={true}
          disabled={true}
          min={formik.values.startAt}
          required
        />

        <LocationInput
          label="Location"
          name="locationId"
          formik={formik}
          readOnly={true}
        />

        <FormElement
          element="InputText"
          id="showType"
          name="showType"
          label="Type"
          formik={formik}
          readOnly={true}
          disabled={true}
          value={show.show_type}
        />
      </div>

      <div className={css.row}>
        <div className={css.col}>
          <FormElement
            element="InputText"
            id="preEntryCost"
            name="preEntryCost"
            label="Pre-entry cost"
            formik={formik}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={`$${formik.values.preEntryCost}`}
            disabled={true}
            required
          />
          <FormErrorMessage name="preEntryCost" formik={formik} />

          <FormElement
            element="InputText"
            id="postEntryCost"
            name="postEntryCost"
            label="Post-entry cost"
            formik={formik}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={`$${formik.values.postEntryCost}`}
            disabled={true}
            required
          />
          <FormErrorMessage name="postEntryCost" formik={formik} />
        </div>

        <div className={css.description}>
          <FormElement
            element="InputTextarea"
            id="description"
            name="description"
            label="Description"
            formik={formik}
            readOnly={true}
            disabled={true}
            value={formik?.values.description}
          />
        </div>
      </div>
    </Form>
  );

  const modals = (formik) => (
    <>
      {showAddClassesModal && (
        <ShowClassModal setShowModal={setShowAddClassesModal} show={show} />
      )}

      {showEditShowModal && (
        <ShowModal
          setShowModal={setShowEditShowModal}
          show={show}
          activeRider={activeRider}
        />
      )}
    </>
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validate={validate}
    >
      {(formik) => (
        <>
          {titleBlock}
          {actions}
          {hero(formik)}
          <Divider />
          {form(formik)}
          {modals(formik)}
        </>
      )}
    </Formik>
  );
}
