import React, { useRef, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";

import bgLogo from "images/dim.svg";
import IconEdit from "components/icons/icon-edit";

import css from "./event-map-input.module.css";

export default function EventMapInput(props) {
  const { formik, name, label, readOnly } = props;

  const avatarRef = useRef();

  const [url, setUrl] = useState(bgLogo);

  useEffect(() => {
    const val = formik.values[name];

    let url = null;
    if (val instanceof File) {
      const url = URL.createObjectURL(val);
      setUrl(url);
    } else if (val?.link) {
      setUrl(val.link);
    }

    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [formik, name]);

  function onFileChange(file) {
    formik.setFieldValue(name, file);
  }

  return (
    <div className={css.container}>
      {!readOnly && (
        <input
          name={name}
          accept="image/*"
          id="contained-button-file"
          type="file"
          ref={avatarRef}
          hidden
          readOnly={readOnly}
          onChange={(e) => onFileChange(e.target.files[0])}
        />
      )}

      {/* <div> */}
      <Image src={url} className={css.img} />
      {!readOnly && (
        <Button
          variant="crystal"
          className={css.btn}
          onClick={(e) => {
            e.preventDefault();
            avatarRef.current.click();
          }}
        >
          <IconEdit />
        </Button>
      )}
      {/* </div> */}
    </div>
  );
}
