import React from "react";

import Icon from "components/icon/icon";

import css from "./empty-block.module.css";

const EmptyBlock = ({
  title,
  subtitle,
  // btnLabel,
  // onClick,
}) => {
  const graphic = (
    <div className={css.graphic}>
      <Icon icon="EmptyBgCircle" stroke="transparent" />
      <Icon icon="Cloud" stroke="transparent" />
      <Icon icon="Circle" fill="#A0A9E4" stroke="transparent" />
      <Icon icon="Circle" fill="#A0A9E4" stroke="transparent" />
      <Icon icon="Circle" fill="#A0A9E4" stroke="transparent" />
      <Icon icon="Circle" fill="#A0A9E4" stroke="transparent" />
    </div>
  );

  const content = (
    <div className={css.content}>
      {graphic}

      <div className={css.intro}>
        {title && <h1>{title}</h1>}
        {subtitle && <p>{subtitle}</p>}
      </div>
    </div>
  );

  // const actions = (
  //   <div className={css.actions}>
  //     <Button type="button" label={btnLabel} onClick={onClick} />
  //   </div>
  // );

  const dots = (
    <div className={css.dots}>
      <div className={css.mask} />
      <Icon icon="DotGrid" fill="#A0A9E4" stroke="transparent" />
    </div>
  );

  return (
    <div className={css.emptyBlock}>
      <div className={css.inner}>
        {content}
        {/* {actions} */}

        {dots}
      </div>
    </div>
  );
};

export default EmptyBlock;
