/** @jsxImportSource @emotion/react */

import { Password as PasswordPR } from "primereact/password";

import css from "../form-element.module.css";

const Password = (data) => {
  const getLabel = data?.label && <label>{data.label}</label>;

  const elProps = {
    ...Object.keys(data).reduce((acc, key) => {
      if (key !== "data") {
        acc[key] = data[key];
      }
      return acc;
    }, {}),
  };

  const getElement = <PasswordPR {...elProps} feedback={false} />;

  return (
    <div className={css.formEl}>
      {getLabel}
      {getElement}
    </div>
  );
};

export default Password;
