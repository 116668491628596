import React from "react";

import Icon from "components/icon/icon";

import { appColors } from "emotion/utils";
import css from "./logo.module.css";

const Logo = () => (
  <div className={css.container}>
    <div className={css.logo}>
      <Icon icon="Logo" stroke="" fill={appColors?.grays?.[0]} />
      <p>Showmanship</p>
    </div>
  </div>
);

export default Logo;
