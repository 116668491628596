import React, { useState } from "react";

import { useQuery } from "hooks/use-query";
import { useMutation } from "hooks/use-mutation";
import Client from "client";

import ClassTile from "./components/class-tile/class-tile";
import SelectButton from "./components/arena-select/arena-select";
import Header from "components/header/header";
import RiderList from "./components/rider-list/rider-list";
import Button from "components/button/button";
// import CustomButton from "components/custom-button/custom-button";

import AnnouncerModal from "./announcer-modal";

import css from "./announcer.module.css";

const Announcer = () => {
  const [canAnnounce, setCanAnnounce] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState("all");
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedShow, setSelectedShow] = useState(null);

  const { data: show, isLoading: isLoadingShow } = useQuery("/announcer/show", {
    onSuccess: (res) => {
      setSelectedShow(res.data || null);
    },
  });

  const { data, isLoading, refetch } = useQuery(`/announcer/show/classes`, {
    onSuccess: (res) => {
      if (!res.data?.length || selectedClass) return;
      setSelectedClass(res.data[0].id);
    },
    enabled: !!selectedShow,
  });

  const { data: riders, isLoading: isRidersLoading } = useQuery(
    `/announcer/show/classes/${selectedClass}/riders`,
    { enabled: !!selectedClass },
  );

  const arenas = data?.data
    .sort((a, b) => a.order - b.order)
    .reduce((map, cls) => {
      if (!map[cls.arena.id]) {
        map[cls.arena.id] = { arena: cls.arena, classes: [] };
      }

      map[cls.arena.id].classes.push(cls);

      return map;
    }, {});

  const filteredClasses =
    selected === "all" ? data?.data : arenas[selected]?.classes;

  const { mutate: shareScores, isLoading: isSharingScores } = useMutation({
    mutationFn: () =>
      Client.post(`/announcer/classes/${selectedClass}/share-scores`),
    onSuccess: () => {
      refetch();
    },
  });

  function handleArenaSelect(id) {
    const cls = id === "all" ? data?.data[0]?.id : arenas[id]?.classes[0]?.id;

    setSelectedClass(cls);
    setSelected(id);
  }

  const navBlock = (
    <div className={css.navBlock}>
      <Header hideMenu={true} />
    </div>
  );

  const titleBar = (
    <div className={css.titleBar}>
      <div>Announcing</div>
      <div>{selectedShow?.name || "No show selected"}</div>
    </div>
  );

  const left = (
    <div className={css.leftColumn}>
      <SelectButton
        options={Object.values(arenas || {}).map((a) => a.arena)}
        selected={selected}
        setSelected={handleArenaSelect}
      />

      <div className={css.list}>
        {(filteredClasses || []).map((cls, index) => (
          <ClassTile
            key={index}
            data={cls}
            selected={selectedClass === cls.id}
            onClick={() => setSelectedClass(cls.id)}
          />
        ))}
      </div>
    </div>
  );

  const cols = ["Rider #", "Name", "Horse Name", "Award"];
  const headCols = (
    <div className={css.headerRow}>
      {cols.map((col, index) => (
        <div key={index} className={css.headerCol}>
          {col}
        </div>
      ))}
    </div>
  );

  const selectedData = data?.data.find((cls) => cls.id === selectedClass);
  const canAnnounceScores =
    selectedData?.time_scores_available && !selectedData?.time_scores_announced;

  const isTrail = selectedClass
    ? selectedData.event_type.scoring_type === "TRAIL"
    : false;

  const right = (
    <div className={css.rightColumn}>
      <div className={css.table}>
        {headCols}

        <RiderList
          data={riders?.data}
          isLoading={isRidersLoading}
          noClass={!selectedClass}
        />
      </div>

      {/* <CustomButton
        label="Share Scores"
        color="secondary"
        onClick={shareScores}
        disabled={!canAnnounceScores || isSharingScores}
        fs={16}
        align="right"
        sty={{
          marginLeft: "auto",
          width: "unset",
        }}
      /> */}
      <Button
        label="Share Scores"
        onClick={shareScores}
        disabled={!isTrail && (!canAnnounceScores || isSharingScores)}
      />
    </div>
  );

  const content = (
    <div className={css.content}>
      {left}
      {right}
    </div>
  );

  const modal = showModal && <AnnouncerModal />;

  return (
    <div className={css.announcer}>
      {navBlock}

      {canAnnounce ? (
        <>
          {titleBar}

          <div className={css.container}>
            {content}
            {modal}
          </div>
        </>
      ) : (
        <div className={css.container}>
          <h1>
            Announcing is not available at the moment.
            <br />
            It will become available at the start of the next show.
          </h1>
        </div>
      )}
    </div>
  );
};

export default Announcer;
