import React from "react";

import Icon from "components/icon/icon";
import { appColors } from "emotion/utils";

import css from "./stats.module.css";

export default function Stats(props) {
  const { data = [], isDetail = false, isOver } = props;

  const statEls = data.map((item) => {
    // Don't show Total Attendees stat if show has not ended
    if (!isOver && item.title === "Total Attendees") {
      return false;
    }

    return (
      <div key={item?.title} className={css.stat}>
        <div className={css.row}>
          <Icon icon={item.icon} stroke={appColors?.blues?.bgBrandSolid} />

          <h3>{item.title}</h3>
        </div>
        <p>
          {item.title === "Income" ? (
            <>${item.entryFunc ? item.entryFunc() : item.entry}</>
          ) : (
            <>{item.entryFunc ? item.entryFunc() : item.entry}</>
          )}
        </p>
      </div>
    );
  });

  return <div className={css.stats}>{statEls}</div>;
}
