/** @jsxImportSource @emotion/react */

import { appColors } from "emotion/utils";
import { capitalizeFirstLetter } from "utils";

import css from "./tag.module.css";

const Tag = ({ tag }) => {
  const createTagStyle = (color) => ({
    backgroundColor: appColors?.[color]?.[50],
    borderColor: appColors?.[color]?.[200],
  });

  const tagStyle = {
    Active: createTagStyle("greens"),
    active: createTagStyle("greens"),
    Yes: createTagStyle("greens"),
    yes: createTagStyle("greens"),
    Paid: createTagStyle("greens"),
    paid: createTagStyle("greens"),
    "Paid (admin)": createTagStyle("greens"),
    "paid (admin)": createTagStyle("greens"),
    No: createTagStyle("blues"),
    no: createTagStyle("blues"),
    Unpaid: createTagStyle("reds"),
    unpaid: createTagStyle("reds"),
    Inactive: createTagStyle("blues"),
    inactive: createTagStyle("blues"),
    default: createTagStyle("blues"),
  };

  const getTagStyle = (tag) => {
    return tagStyle[tag] || tagStyle.default;
  };

  return (
    <span className={css.tag} css={getTagStyle(tag)}>
      {capitalizeFirstLetter(tag)}
    </span>
  );
};

export default Tag;
