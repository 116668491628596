import React, { useState } from "react";
import { Outlet, useParams } from "react-router-dom";

import { useQuery } from "hooks";

import css from "./show.module.css";

export default function Show() {
  const { showId } = useParams();

  const [, setActiveBtnGroup] = useState("overview");

  const {
    data: show,
    refetch,
    isLoading,
    isError,
  } = useQuery(`/shows/byId/${showId}`);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error!!!</div>;
  }

  return (
    <div className={css.show}>
      <Outlet
        context={{
          show,
          setActiveBtnGroup,
          onSuccess: () => {
            refetch();
          },
        }}
      ></Outlet>
    </div>
  );
}
