import React from "react";

import css from "./arena-select.module.css";

const SelectButton = ({ options, selected = "all", setSelected }) => (
  <div className={css.selectButton}>
    <div className={css.select}>
      <button
        className={`${css.selectButton} ${selected === "all" && css.selected}`}
        onClick={() => setSelected("all")}
      >
        All
      </button>

      {options.map((opt, index) => (
        <button
          key={index}
          className={`${css.selectButton} ${
            selected === opt.id && css.selected
          }`}
          onClick={() => setSelected(opt.id)}
        >
          {opt.name}
        </button>
      ))}
    </div>
  </div>
);

export default SelectButton;
